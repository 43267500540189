import { firebaseConfig } from "../../../config/firebaseConfig";
//import { getDatabase, ref, set, get, child } from "firebase/database";
import {
  getDatabase,
  ref,
  set,
  query,
  orderByChild,
  equalTo,
  get,
} from "firebase/database";

import axios from "axios";
import router from "@/router"; // oder wo immer Ihr Router definiert ist

let timer;

const state = {
  userId: null,
  token: null,
  refreshToken: null,
  userName: null,
  userRole: null, // Neues Feld für die Rolle
};

const mutations = {
  setUser(state, payload) {
    state.userId = payload.userId;
    state.token = payload.token;
    state.refreshToken = payload.refreshToken;
    state.userName = payload.userName;
    state.userRole = payload.userRole || state.userRole; // Nur überschreiben, wenn vorhanden
  },
  setUserRole(state, role) {
    state.userRole = role;
  },
};


const actions = {
  async getUserByEmail(email) {
    const db = getDatabase();
    const usersRef = ref(db, "users");

    // Query zur Suche nach der E-Mail-Adresse
    const emailQuery = query(usersRef, orderByChild("email"), equalTo(email));

    try {
      const snapshot = await get(emailQuery);

      if (snapshot.exists()) {
        const users = snapshot.val();
        const userId = Object.keys(users)[0]; // Hole die erste gefundene UID
        const userData = users[userId]; // Hole die Benutzerdaten
        console.log("Benutzer gefunden:", userData);

        return { userId, ...userData }; // Gebe die UID und die Benutzerdaten zurück
      } else {
        console.error("Kein Benutzer mit dieser E-Mail gefunden.");
        return null;
      }
    } catch (error) {
      console.error("Fehler beim Abrufen des Benutzers:", error);
      throw error;
    }
  },

  auth(context, payload) {
    let url = "";
    if (payload.mode === "signin") {
      url = `https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=${firebaseConfig.apiKey}`;
    } else if (payload.mode === "signup") {
      url = `https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=${firebaseConfig.apiKey}`;
    } else {
      return;
    }
  
    const authDO = {
      email: payload.email,
      password: payload.password,
      returnSecureToken: true,
    };
  
    return axios
      .post(url, authDO)
      .then(async (response) => {
        const expiresIn = Number(response.data.expiresIn) * 1000;
        const expDate = new Date().getTime() + expiresIn;
  
        // Token und User-Daten im LocalStorage speichern
        localStorage.setItem("token", response.data.idToken);
        localStorage.setItem("userId", response.data.localId);
        localStorage.setItem("userName", response.data.email);
        localStorage.setItem("expiresIn", expDate);
        localStorage.setItem("refreshToken", response.data.refreshToken);
  
        timer = setTimeout(() => {
          context.dispatch("refreshToken");
        }, expiresIn - 10000);
  
        // Benutzer im Store setzen
        context.commit("setUser", {
          userId: response.data.localId,
          token: response.data.idToken,
          refreshToken: response.data.refreshToken,
          userName: response.data.email,
        });
  
        // Benutzerrolle aus der Datenbank abrufen oder erstellen
        const db = getDatabase();
        const userRef = ref(db, `users/${response.data.localId}`);
        const snapshot = await get(userRef);
  
        if (!snapshot.exists()) {
          // Benutzer erstellen, wenn nicht vorhanden
          await set(userRef, {
            email: response.data.email,
            role: "user",
          });
          context.commit("setUserRole", "user"); // Standardrolle setzen
        } else {
          // Benutzerrolle abrufen
          const userData = snapshot.val();
          context.commit("setUserRole", userData.role); // Gefundene Rolle setzen
        }
      })
      .catch((error) => {
        const errorMessage = new Error(
          error.response.data.error.message || "UNKNOWN_ERROR"
        );
        throw errorMessage;
      });
  },
  

  refreshToken(context) {
    const refreshToken = localStorage.getItem("refreshToken");
    const userName = localStorage.getItem("userName");

    if (!refreshToken) {
      context.dispatch("signout"); // Wenn kein Refresh-Token vorhanden ist, abmelden
      return;
    }

    const refreshTokenUrl = `https://securetoken.googleapis.com/v1/token?key=${firebaseConfig.apiKey}`; // Ersetze durch den tatsächlichen Auth-Server-Endpunkt
    const refreshTokenDO = {
      grant_type: "refresh_token",
      refresh_token: refreshToken,
    };

    axios
      .post(refreshTokenUrl, refreshTokenDO)
      .then((response) => {
        console.log("REFRESH-RESPONSE", response);
        const expiresIn = Number(response.data.expires_in) * 1000;
        console.log("expiresIn", expiresIn);
        const expDate = new Date().getTime() + expiresIn;
        console.log("expDate", expDate);

        localStorage.setItem("token", response.data.id_token);
        localStorage.setItem("expiresIn", expDate);

        localStorage.setItem("refreshed", true);
        localStorage.setItem("refreshToken", response.data.refresh_token); // Das Refresh-Token speichern

        context.commit("setUser", {
          token: response.data.idToken,
          refreshToken: refreshToken, // Das Refresh-Token bleibt unverändert
          userName: userName,
        });

        // Setze den Timer erneut für das aktualisierte Zugriffstoken
        clearTimeout(timer);
        timer = setTimeout(() => {
          context.dispatch("refreshToken");
          console.log("refresh");
        }, expiresIn);
      })
      .catch((error) => {
        console.error(
          "Fehler bei der Aktualisierung des Zugriffstokens:",
          error
        );
        context.dispatch("signout"); // Bei einem Fehler das Benutzerkonto abmelden
      });
  },

  signup(context, payload) {
    const signupDO = {
      ...payload,
      mode: "signup",
    };
    return context.dispatch("auth", signupDO);
  },
  signin(context, payload) {
    const signinDO = {
      ...payload,
      mode: "signin",
    };
    return context.dispatch("auth", signinDO);
  },
  async autoSignin(context) {
    const token = localStorage.getItem("token");
    const userId = localStorage.getItem("userId");
    const userName = localStorage.getItem("userName");
  
    if (!token || !userId) return;
  
    const expiresIn = localStorage.getItem("expiresIn");
    const timeLeft = Number(expiresIn) - new Date().getTime();
  
    if (timeLeft < 0) {
      return;
    }
  
    timer = setTimeout(() => {
      context.dispatch("autoSignout");
      router.push("/");
    }, timeLeft);
  
    context.commit("setUser", {
      token: token,
      userId: userId,
      userName: userName,
    });
  
    // Benutzerrolle aus der Datenbank abrufen
    const db = getDatabase();
    const userRef = ref(db, `users/${userId}`);
    const snapshot = await get(userRef);
  
    if (snapshot.exists()) {
      const userData = snapshot.val();
      context.commit("setUserRole", userData.role);
    } else {
      context.commit("setUserRole", null);
    }
  },
  
  signout(context) {
    localStorage.removeItem("token");
    localStorage.removeItem("userId");
    localStorage.removeItem("userName");
    localStorage.removeItem("expiresIn");
    localStorage.removeItem("refreshToken"); // Das Refresh-Token entfernen

    clearTimeout(timer);

    context.commit("setUser", {
      token: null,
      userId: null,
      userName: null,
      refreshToken: null, // Das Refresh-Token zurücksetzen
    });
  },
  autoSignout(context) {
    // Server-Kommunikation, falls notwendig
    context.dispatch("signout");
  },
};

const getters = {
  isAuthenticated: (state) => !!state.token,
  token: (state) => state.token,
  refreshToken: (state) => state.refreshToken,
  userName: (state) => state.userName,
  userRole: (state) => state.userRole, // Getter für die Rolle
};

const authModule = {
  state,
  mutations,
  actions,
  getters,
};

export default authModule;
